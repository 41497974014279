import React, {lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faTwitch, faYoutube, faDiscord} from '@fortawesome/free-brands-svg-icons'
import {
    faAngleDown,
    faAngleLeft,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faAt,
    faBullhorn,
    faCheck,
    faCode,
    faCogs,
    faComments,
    faEdit,
    faExclamationTriangle,
    faEye,
    faFilm,
    faFingerprint,
    faFire,
    faHashtag,
    faMicrophone,
    faPencilAlt,
    faPowerOff,
    faShareAlt,
    faSyncAlt,
    faTachometerAlt,
    faTimes,
    faTrash,
    faTrashAlt,
    faUsers,
    faUndoAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
    faCheckCircle,
} from '@fortawesome/free-regular-svg-icons'

library.add(
    faAngleDown,
    faAngleLeft,
    faArrowDown,
    faArrowUp,
    faArrowLeft,
    faArrowRight,
    faAt,
    faBullhorn,
    faCheck,
    faCheckCircle,
    faCode,
    faCogs,
    faComments,
    faDiscord,
    faEdit,
    faExclamationTriangle,
    faEye,
    faFilm,
    faFingerprint,
    faFire,
    faHashtag,
    faMicrophone,
    faPencilAlt,
    faPowerOff,
    faShareAlt,
    faSyncAlt,
    faTachometerAlt,
    faTimes,
    faTrash,
    faTrashAlt,
    faTwitch,
    faUsers,
    faUndoAlt,
    faYoutube,
)

function subdomainApplications (map) {
    let main = map.find((item)=> item.main)
    if (!main) {
        throw new Error('Must set main flag to true on at least one subdomain app')
    }
  
    return function getComponent () {
        if (window.location.hostname === 'localhost') {
            const parts = window.location.pathname.slice(1).split('/')
            const app = map.find(({subdomains}) => subdomains.includes(parts[0]))
        
            return app ? app.application : main.application
        }
        
        const parts = window.location.hostname.split('.')

        let last_index = -2
        const last = parts[parts.length - 1]
        const is_localhost = last === 'localhost'
        if (is_localhost) {
            last_index = -1
        }

        const subdomain = parts.slice(0, last_index).join('.')

        if (!subdomain) {
            return main.application
        }
  
        const app = map.find(({subdomains}) => subdomains.includes(subdomain))
        
        return app ? app.application : main.application
    }
}
  
const getApp = subdomainApplications([
    {
        subdomains: ['', 'www'],
        application: lazy(() => import('./applications/site/App')),
        main: true
    },
    {
        subdomains: ['profile'],
        application: lazy(() => import('./applications/creators/App')),
    },
    {
        subdomains: ['mod', 'guild'],
        application: lazy(() => import('./applications/guild/App')),
    },
    {
        subdomains: ['hystream'],
        application: lazy(() => import('./applications/hystream/App')),
    },
    {
        subdomains: ['lord'],
        application: lazy(() => import('./applications/lord/App')),
    }
])

const App = getApp()

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading...</div>}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
